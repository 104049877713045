<template>
  <div class="inbox-feedback">
    <div class="header-toggle">
      <h2>{{ title }}</h2>
    </div>
    <div class="header-toggle">
      <h5>{{ $lang.settingsPage_FeedbackRating }}</h5>
      <common-toggle v-model="questions[0].isVisible"></common-toggle>
    </div>
    <label v-if="ratingQuestion.isVisible" class="form-group">
      <div class="form-label light">
        {{ $lang.settingsPage_FeedbackRatingDescription }}
      </div>
      <common-input
        v-model.trim="questions[0].text"
        :maxLength="500"></common-input>
    </label>

    <div class="header-toggle">
      <h5>{{ $lang.settingsPage_FeedbackAdditional }}</h5>
      <common-toggle v-model="areAdditionalQuestionsVisible"></common-toggle>
    </div>
    <template v-if="areAdditionalQuestionsVisible">
      <div class="form-label light">
        {{ $lang.settingsPage_FeedbackAdditionalDescription }}
      </div>
      <div
        v-for="(x, index) in questions.slice(1)"
        :key="index"
        class="question">
        <span class="number">{{ index + 1 }}.</span>
        <common-input
          v-model="questions[index + 1].text"
          :maxLength="500"></common-input>
        <common-button
          variant="icon"
          icon
          iconOnly
          @click="removeQuestion(index + 1)">
          <template slot="icon"><ppicon name="close-circle" /></template>
        </common-button>
      </div>
      <common-button
        variant="primary"
        icon
        iconOnly
        @click="addQuestion"
        :disabled="questions.length > maxAdditionalQuestions">
        <template slot="icon">
          <ppicon name="plus" />
        </template>
      </common-button>
    </template>
  </div>
</template>
<script>
  export default {
    name: "InboxFeedback",
    props: {
      value: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      isAmbassador: {
        type: Boolean,
        default: false,
      },
      organizationId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        areAdditionalQuestionsVisible: true,
        maxAdditionalQuestions: 5,
        questions: [
          {
            text: "",
            type: "slider",
            isVisible: true,
            isActive: true,
            usage: this.isAmbassador
              ? "feedbackAmbassador"
              : "feedbackProspect",
            organizationId: this.organizationId,
          },
          {
            text: "",
            type: "text",
            isVisible: true,
            isActive: true,
            usage: this.isAmbassador
              ? "feedbackAmbassador"
              : "feedbackProspect",
            organizationId: this.organizationId,
          },
        ],
      };
    },
    watch: {
      value: {
        immediate: true,
        handler(newValue) {
          if (newValue?.length > 0) {
            this.questions = newValue;
            this.areAdditionalQuestionsVisible = this.questions
              .slice(1)
              .every((question) => question.isVisible);
          }
        },
      },
      areAdditionalQuestionsVisible(value) {
        this.additionalQuestions.forEach(
          (question) => (question.isVisible = value)
        );
      },
      questions: {
        deep: true,
        immediate: true,
        handler(value) {
          this.$emit("input", value);
        },
      },
    },
    computed: {
      ratingQuestion() {
        return this.questions[0];
      },
      additionalQuestions() {
        return this.questions.slice(1);
      },
      allQuestions() {
        return [this.ratingQuestion, ...this.additionalQuestions];
      },
    },
    mounted() {
      if (this.value?.length > 0) {
        this.questions = this.value;
        this.areAdditionalQuestionsVisible = this.questions
          .slice(1)
          .every((question) => question.isVisible);
      }
    },
    methods: {
      addQuestion() {
        this.questions.push({
          text: "",
          type: "text",
          isVisible: true,
          isActive: true,
          usage: this.isAmbassador ? "feedbackAmbassador" : "feedbackProspect",
          organizationId: this.organizationId,
        });
      },
      removeQuestion(index) {
        this.questions = [...this.questions.filter((x, i) => i !== index)];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .inbox-feedback {
    margin-bottom: 40px;
  }
  .header-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;

    h2 {
      color: $primary-text;
      font-size: 24px;
      font-weight: 500;
    }

    h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1px;
      color: $grey-500;
    }
  }

  .form-group {
    display: block;
    padding-bottom: 1rem;
  }
  .form-label {
    font-weight: 500;
    padding-bottom: 0.5rem;
    &.light {
      font-weight: 400;
    }
  }

  .question {
    display: flex;
    margin-bottom: 20px;

    .number {
      margin: auto 15px auto 0;
    }
  }
</style>
