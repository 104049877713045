<template>
  <div class="inbox-page">
    <div class="header-toggle">
      <h2>{{ $lang.settingsPage_InboxWelcomeMessageHeader }}</h2>
    </div>

    <div class="form-group">
      <label class="form-label">
        {{ $lang.settingsPage_InboxWelcomeMessageAmbassadorsHeader }}
      </label>
      <p class="form-description">
        {{ $lang.settingsPage_InboxAmbassadorWelcomeMessageDescription }}
      </p>
      <common-textarea v-model="ambassadorMessage" maxlength="1000" />
      <p v-if="ambassadorMessageWarning" class="form-warning">
        <mdicon name="alert-triangle" size="12" />{{ ambassadorMessageWarning }}
      </p>
    </div>

    <div class="form-group">
      <label class="form-label">
        {{ $lang.settingsPage_InboxWelcomeMessageProspectsHeader }}
      </label>
      <p class="form-description">
        {{ $lang.settingsPage_InboxProspectWelcomeMessageDescription }}
      </p>
      <common-textarea v-model="prospectMessage" maxlength="1000" />
      <p v-if="prospectMessageWarning" class="form-warning">
        <mdicon name="alert-triangle" size="12" />{{ prospectMessageWarning }}
      </p>
    </div>

    <InboxFeedback
      :title="$lang.settingsPage_FeedbackHeaderAmbassador"
      :organizationId="branding._id"
      v-model="ambassadorFeedbackQuestions"
      isAmbassador />
    <InboxFeedback
      :title="$lang.settingsPage_FeedbackHeaderProspect"
      :organizationId="branding._id"
      v-model="prospectFeedbackQuestions" />

    <div class="header-toggle">
      <h2>{{ $lang.settingsPage_InboxContactUsHeader }}</h2>
      <common-toggle v-model="contactIsActive"></common-toggle>
    </div>
    <div v-if="contactIsActive">
      <label class="form-group">
        <div class="form-label">
          {{ $lang.settingsPage_InboxContactUsLabel }}
        </div>
        <common-input
          v-model.trim="contactLabel"
          :maxLength="24"></common-input>
      </label>
      <label class="form-group">
        <div class="form-label">
          {{ $lang.settingsPage_InboxContactUsEmail }}
        </div>
        <common-input v-model.trim="contactEmail"></common-input>
      </label>
    </div>

    <common-button variant="primary" class="btn-save" @click="save">
      {{ $lang.general_Save }}
    </common-button>
  </div>
</template>

<script>
  import validationsService from "@/services/validations.service";
  import { SET_BRANDING } from "@/store/actions.type";
  import { mapActions } from "vuex";
  import organizationService from "@/services/api/organization.service.js";
  import InboxFeedback from "@/components/dashboard/settings/settingsComponents/customize_platform/InboxFeedback.vue";

  export default {
    name: "Inbox",
    components: {
      InboxFeedback,
    },
    props: {
      branding: Object,
    },
    data() {
      return {
        ambassadorMessage: this.branding.cWelMess,
        prospectMessage: this.branding.nWelMess,
        contactIsActive: this.branding.contactIsActive,
        contactLabel: this.branding.contactLabel,
        contactEmail: this.branding.contactEmail,
        ambassadorFeedbackQuestions: [],
        prospectFeedbackQuestions: [],
      };
    },
    computed: {
      ambassadorMessageWarning() {
        const invalids = this.getInvalidTags(this.ambassadorMessage);
        if (invalids.length) {
          return this.$lang.settingsPage_InboxWelcomeMessageInvalidTag.format(
            invalids.join(", ")
          );
        }
        return "";
      },
      prospectMessageWarning() {
        const invalids = this.getInvalidTags(this.prospectMessage);
        if (invalids.length) {
          return this.$lang.settingsPage_InboxWelcomeMessageInvalidTag.format(
            invalids.join(", ")
          );
        }
        return "";
      },
      areFeedbackQuestionsInvalid() {
        return !(
          this.visibleAmbassadorFeedbackQuestions.every((question) =>
            question.text.trim()
          ) &&
          this.visibleProspectFeedbackQuestions.every((question) =>
            question.text.trim()
          )
        );
      },
      visibleAmbassadorFeedbackQuestions() {
        return this.ambassadorFeedbackQuestions.filter(
          (question) => question.isVisible
        );
      },
      visibleProspectFeedbackQuestions() {
        return this.prospectFeedbackQuestions.filter(
          (question) => question.isVisible
        );
      },
    },
    methods: {
      ...mapActions({ setBranding: SET_BRANDING }),

      async save() {
        if (this.areFeedbackQuestionsInvalid) {
          this.$toasted.show(
            this.$lang.settingsPage_FeedbackError,
            this.$toastedFailure
          );
          return;
        }
        if (this.contactIsActive) {
          if (!validationsService.isEmailValid(this.contactEmail)) {
            this.$toasted.show(
              this.$lang.general_InvalidEmail,
              this.$toastedFailure
            );
            return;
          }
        }

        try {
          const results = await Promise.all([
            organizationService.postConversationFeedbackQuestions(
              this.branding._id,
              this.ambassadorFeedbackQuestions,
              this.prospectFeedbackQuestions
            ),
            this.setBranding({
              cWelMess: this.ambassadorMessage,
              nWelMess: this.prospectMessage,
              contactIsActive: this.contactIsActive,
              contactLabel: this.contactLabel,
              contactEmail: this.contactEmail,
            }),
          ]);
          this.ambassadorFeedbackQuestions =
            results[0].data.conversationFeedbackQuestionsAmbassador;
          this.prospectFeedbackQuestions =
            results[0].data.conversationFeedbackQuestionsProspect;
        } catch {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        } finally {
          this.$toasted.show(
            this.$lang.settingsPage_LandingPageSaveSuccess,
            this.$toastedSuccess
          );
        }
      },

      getInvalidTags(text) {
        const regex = /{(.+?)}/g;
        const tags = ["firstname", "lastname"];
        const invalid = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
          const tag = match[1].trim().toLowerCase();
          if (tags.indexOf(tag) === -1) {
            invalid.push(match[0]);
          }
        }
        return invalid;
      },
    },
    async mounted() {
      const questions = (
        await organizationService.getConversationFeedbackQuestions(
          this.branding._id
        )
      ).data;
      this.ambassadorFeedbackQuestions =
        questions.conversationFeedbackQuestionsAmbassador || [];
      this.prospectFeedbackQuestions =
        questions.conversationFeedbackQuestionsProspect || [];
    },
  };
</script>

<style scoped lang="scss">
  .inbox-page {
    font-size: 14px;
    color: #757e8c;
    max-width: 600px;
    padding-bottom: 1rem;
  }

  .header-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;

    h2 {
      color: $primary-text;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .form-group {
    display: block;
    padding-bottom: 1rem;
  }
  .form-label {
    font-weight: 500;
    padding-bottom: 0.5rem;
  }
  .form-description {
    padding: 1em 0;
  }
  .form-warning {
    display: flex;
    gap: 0.5em;
    align-items: center;
    color: $g-orange;
    font-size: 12px;
  }

  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
</style>
